import * as React from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { ThemeProvider } from "@saleor/macaw-ui";
import { IntlProvider } from "react-intl";
import { useFormattedMessages } from "@/saleor-app-checkout/frontend/hooks/useFormattedMessages";
import AppContainer from "@/saleor-app-checkout/frontend/components/elements/AppContainer";
import AppProvider from "@/saleor-app-checkout/frontend/components/elements/AppProvider";
import PrivateSettingsProvider from "@/saleor-app-checkout/frontend/components/elements/PrivateSettingsProvider";
import "@saleor/checkout-storefront/dist/index.css";
import { useEffect } from "react";
import { RoutePropagator } from "@saleor/app-sdk/app-bridge/next";
import Script from "next/script";
declare global {
  // eslint-disable-next-line no-var -- var is required here
  var __SALEOR_CHECKOUT_ENV__: string;
}
declare global {
  interface Window {
    rudderanalytics: any;
    heap: any;
    gtag: any;
    fbq: any;
    Grin: any;
    tp: any;
    VWO: any;
  }
}
export default function App(props: AppProps) {
  const { Component, pageProps } = props;

  const { locale, messages } = useFormattedMessages();

  const version = [
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || "(unknown_env)",
    process.env.NEXT_PUBLIC_GIT_BRANCH || "(unknown_branch)",
    process.env.NEXT_PUBLIC_SENTRY_RELEASE || "(unknown_release)",
  ].join("-");

  const HEAP_PROJECT_ID = process.env.NEXT_PUBLIC_HEAP_PROJECT_ID || "saleor_app_checkout_heapid";
  const NMI_API_KEY = process.env.NEXT_PUBLIC_NMI_API_KEY;

  const PIXEL_ID = process.env.NEXT_PUBLIC_PIXEL_ID || "";

  const metaPixelScript = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${PIXEL_ID}');
  fbq('track', 'PageView');`;

  useEffect(() => {
    globalThis.__SALEOR_CHECKOUT_ENV__ = version;
  }, [version]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
        <style>
          {`
            html,
            body {
              height: 100%;
              width: 100%;
              font-family: "Outfit", sans-serif !important;
            }
            *,
            *:after,
            *:before {
              box-sizing: border-box;
            }
            body {
              font-size: 1rem;
              margin: 0;
              background: transparent !important;
            }
          `}
        </style>
        <script id="meta-pixel" dangerouslySetInnerHTML={{ __html: metaPixelScript }} />
      </Head>
      <Script id="heapio">
        {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
heap.load("${HEAP_PROJECT_ID}")`}
      </Script>
      <Script
        id="nmiApiKey"
        src="https://secure.safewebservices.com/token/Collect.js"
        data-tokenization-key={NMI_API_KEY}
        data-field-ccnumber-enable-card-brand-previews="true"
        data-style-sniffer="true"
      ></Script>
      <AppProvider>
        <PrivateSettingsProvider>
          <IntlProvider
            locale={locale}
            messages={messages}
            onError={() => null} // Hide missing translation warnings
          >
            {/* @ts-expect-error React 17 <-> 18 types mismatch */}
            <ThemeProvider ssr={true}>
              <RoutePropagator />
              <AppContainer>
                <Component {...pageProps} />
              </AppContainer>
            </ThemeProvider>
          </IntlProvider>
        </PrivateSettingsProvider>
      </AppProvider>
    </>
  );
}
